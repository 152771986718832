<template>
  <span>
    <v-card>
      <v-card-title>
        Realizados
      </v-card-title>

      <v-card-text style="max-height: 185px; overflow: auto;">
        <v-simple-table class="table-indicadores" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th v-for="category in categories" :key="category">
                  {{ category }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="white-space: nowrap;">
                  Quant. prazo
                </td>
                <td v-for="category in categories" :key="category">
                  {{
                    category in dadosRealizados
                      ? Number(dadosRealizados[category].qtd_prazo)
                      : "-" | parseNumberToFloatBrIgnoringNaN
                  }}
                </td>
              </tr>
              <tr>
                <td style="white-space: nowrap;">
                  Quant. fora prazo (SERV_008)
                </td>
                <td v-for="category in categories" :key="category">
                  {{
                    category in dadosRealizados
                      ? Number(dadosRealizados[category].qtd_fora_prazo)
                      : "-" | parseNumberToFloatBrIgnoringNaN
                  }}
                </td>
              </tr>
              <tr>
                <td style="white-space: nowrap;">
                  Total (SERV_006)
                </td>
                <td v-for="category in categories" :key="category">
                  {{
                    category in dadosRealizados
                      ? Number(dadosRealizados[category].total)
                      : "-" | parseNumberToFloatBrIgnoringNaN
                  }}
                </td>
              </tr>
              <tr>
                <td style="white-space: nowrap;">
                  PM total (SERV_007)
                </td>
                <td v-for="category in categories" :key="category">
                  {{
                    category in dadosRealizados
                      ? Number(dadosRealizados[category].pm_total)
                      : "-" | parseNumberToFloatBrIgnoringNaN
                  }}
                </td>
              </tr>
              <tr>
                <td style="white-space: nowrap;">
                  PM fora prazo (SERV_009)
                </td>
                <td v-for="category in categories" :key="category">
                  {{
                    category in dadosRealizados
                      ? Number(dadosRealizados[category].pm_fora_prazo)
                      : "-" | parseNumberToFloatBrIgnoringNaN
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-text>
        <highcharts
          :options="chartPrazoMedioOptions"
          :deepCopyOnUpdate="false"
        />

        <v-radio-group v-model="visaoGraficoQuantidades" row class="mb-0 ml-2">
          <v-radio label="Valores" value="valor" />
          <v-radio label="Porcentagem" value="porcentagem" />
        </v-radio-group>
        <highcharts
          :options="chartQuantidadesOptions"
          :deepCopyOnUpdate="false"
        />
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
  import IndicadoresServicosV2Service from "@/services/IndicadoresServicosV2Service.js";

  import dayjs from "dayjs";
  import customParseFormat from "dayjs/plugin/customParseFormat";
  dayjs.extend(customParseFormat);

  import Highcharts from "highcharts";
  import HighchartsNoData from "highcharts/modules/no-data-to-display";
  HighchartsNoData(Highcharts);

  export default {
    name: "ChartsRealizadosServicos",
    props: {
      competencias: {
        type: Array,
        required: true,
      },
      competencia_de: {
        type: String,
        required: true,
      },
      competencia_ate: {
        type: String,
        required: true,
      },
      anos: {
        type: Array,
        required: true,
      },
      meses: {
        type: Array,
        required: true,
      },
      servico: {
        type: Number,
        required: true,
      },
      prazoReguladoServico: {
        type: Number,
        required: false,
        default: 0,
      },
      visaoGraficos: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      chartPrazoMedioOptions: {},
      chartQuantidadesOptions: {},
      dadosRealizados: {},
      visaoGraficoQuantidades: "valor",
    }),
    computed: {
      categories() {
        return this.visaoGraficos === "mensal"
          ? this.setCategoriesVisaoMensal(this.competencias)
          : this.setCategoriesVisaoAnual(this.anos);
      },
    },
    mounted() {
      this.$emit("setComponentIsMounted", "chartsRealizadosServicos");
    },
    methods: {
      setCategoriesVisaoAnual(categories) {
        // Solicitação do Tiburcio: deve haver um range de pelo menos 5 anos na visualização do dashboard
        if (categories.length < 5) {
          const stop = Number(categories[0]) + 4;
          const start = Number(categories[0]);
          const step = 1;

          categories = Array.from(
            { length: (stop - start) / step + 1 },
            (value, index) => start + index * step
          );
        }

        return categories;
      },
      setCategoriesVisaoMensal(categories) {
        // Solicitação do Tiburcio: deve haver um range de pelo menos 12 meses na visualização do dashboard
        if (categories.length < 12) {
          const startDate = dayjs(categories[0], "MM/YYYY").format("YYYY-MM");
          const endDate = dayjs(categories[0], "MM/YYYY")
            .add(11, "month")
            .format("YYYY-MM");

          let start = startDate.split("-");
          let end = endDate.split("-");
          let startYear = parseInt(start[0]);
          let endYear = parseInt(end[0]);
          let dates = [];

          for (let i = startYear; i <= endYear; i++) {
            let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
            let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;

            for (
              let j = startMon;
              j <= endMonth;
              j = j > 12 ? j % 12 || 11 : j + 1
            ) {
              let month = j + 1;
              let displayMonth = month < 10 ? "0" + month : month;
              dates.push([displayMonth, i].join("/"));
            }
          }

          categories = dates;
        }

        return categories;
      },
      getDados(
        competencia_de = this.competencia_de,
        competencia_ate = this.competencia_ate,
        servico = this.servico,
        prazoReguladoServico = this.prazoReguladoServico,
        competencias = this.competencias,
        anos = this.anos
      ) {
        this.$emit("updateLoadingDados", true);

        return IndicadoresServicosV2Service.getDadosCardRealizados(
          competencia_de,
          competencia_ate,
          servico
        )
          .then((res) => {
            this.dadosRealizados = res;

            const categories =
              this.visaoGraficos === "mensal"
                ? this.setCategoriesVisaoMensal(competencias)
                : this.setCategoriesVisaoAnual(anos);

            this.chartPrazoMedioOptions = this.setChartPrazoMedioOptions(
              categories,
              prazoReguladoServico
            );
            this.chartQuantidadesOptions = this.setChartQuantidadesOptions(
              categories
            );
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar dados Realizados.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          })
          .finally(() => {
            this.$emit("updateLoadingDados", false);
          });
      },
      setChartPrazoMedioOptions(categories, prazoReguladoServico) {
        return {
          chart: {
            type: "spline",
            zoomType: "xy",
            height: 200,
          },
          credits: { enabled: false },
          title: { text: null },
          subtitle: { text: null },
          legend: { enabled: true },
          xAxis: {
            categories: categories,
            crosshair: true,
            title: {
              text: this.visaoGraficos === "mensal" ? "Competência" : "Ano",
            },
          },
          yAxis: {
            title: { text: null },
          },
          tooltip: {
            shared: true,
            valueDecimals: 2,
          },
          plotOptions: {
            valueDecimals: 2,
            series: {
              marker: {
                // enabledThreshold: 12,
                radius: 2,
              },
              animation: false,
              connectNulls: true,
            },
          },
          lang: {
            noData: "Sem dados para exibição",
          },
          series: [
            {
              name: "PM Total (SERV_007)",
              animation: false,
              color: "#003a63",
              data: categories.map((category) => {
                return category in this.dadosRealizados
                  ? {
                      x: categories.indexOf(category),
                      y: Number(this.dadosRealizados[category].pm_total),
                    }
                  : { x: categories.indexOf(category), y: null };
              }),
              // .filter(val => val !== null && val.y !== null && val.y > 0)
            },
            {
              name: "PM Fora Prazo (SERV_009)",
              animation: false,
              color: "#FF5252",
              data: categories.map((category) => {
                return category in this.dadosRealizados
                  ? {
                      x: categories.indexOf(category),
                      y: Number(this.dadosRealizados[category].pm_fora_prazo),
                    }
                  : { x: categories.indexOf(category), y: null };
              }),
              // .filter(val => val !== null && val.y !== null && val.y > 0)
            },
            {
              name: "Prazo Regulado",
              animation: false,
              color: "#4CAF50",
              data: categories.map((category) => {
                return {
                  x: categories.indexOf(category),
                  y: prazoReguladoServico,
                };
              }),
            },
          ],
        };
      },
      setChartQuantidadesOptions(categories) {
        return {
          chart: {
            type: "column",
            zoomType: "xy",
            height: 200,
          },
          colors: ["#003a63", "#FF5252"],
          credits: { enabled: false },
          title: { text: null },
          subtitle: { text: null },
          legend: { enabled: true },
          xAxis: {
            categories: categories,
            crosshair: true,
            title: {
              text: this.visaoGraficos === "mensal" ? "Competência" : "Ano",
            },
          },
          yAxis: {
            title: { text: null },
            labels: {
              format:
                this.visaoGraficoQuantidades === "porcentagem"
                  ? "{value}%"
                  : "{value}",
            },
          },
          plotOptions: {
            column: {
              stacking:
                this.visaoGraficoQuantidades === "porcentagem"
                  ? "percent"
                  : "normal",
            },
          },
          tooltip: {
            shared: true,
          },
          lang: {
            noData: "Sem dados para exibição",
          },
          series: [
            {
              name: "Quant. prazo",
              animation: false,
              data: categories.map((category) => {
                return category in this.dadosRealizados
                  ? {
                      x: categories.indexOf(category),
                      y: Number(this.dadosRealizados[category].qtd_prazo),
                    }
                  : { x: categories.indexOf(category), y: null };
              }),
              // .filter(val => val !== null && val.y !== null && val.y > 0)
            },
            {
              name: "Quant. fora prazo (SERV_008)",
              animation: false,
              data: categories.map((category) => {
                return category in this.dadosRealizados
                  ? {
                      x: categories.indexOf(category),
                      y: Number(this.dadosRealizados[category].qtd_fora_prazo),
                    }
                  : { x: categories.indexOf(category), y: null };
              }),
              // .filter(val => val !== null && val.y !== null && val.y > 0)
            },
          ],
        };
      },
    },
    watch: {
      visaoGraficos() {
        this.chartPrazoMedioOptions = this.setChartPrazoMedioOptions(
          this.categories,
          this.prazoReguladoServico
        );
        this.chartQuantidadesOptions = this.setChartQuantidadesOptions(
          this.categories
        );
      },
      visaoGraficoQuantidades() {
        this.chartQuantidadesOptions = this.setChartQuantidadesOptions(
          this.categories
        );
      },
    },
  };
</script>

<style>
  .table-indicadores table tbody tr td {
    font-size: 12px;
    padding: 0px 8px !important;
    text-align: end;
  }
  .table-indicadores table thead tr th {
    font-size: 12px;
    height: 25px;
    text-align: end !important;
    font-weight: 500;
  }
</style>
